.BotonFinalizar.btn.btn-contained
{
    background-color:#2577DF; 
    color: #FFFFFF;
    margin: 3px 0px 2px;
    height: 45px;
    font-family: 'Roboto sans-serif';
}


@media only screen and (min-width: 800px) {
    .BotonFinalizar.btn.btn-contained{
        width: 550px;
        height: 50px;
        margin-left: -94px;
        margin-right: -100px;
      }      
    }
    
@media only screen and (min-width: 600px) and (max-width: 799px) {
    .BotonFinalizar.btn.btn-contained 
    {
    width: 450px;
    margin-left: -15px;
    margin-right: -41px;
  }
}


@media only screen and (max-width: 599px) and (min-width: 480px) {
    .BotonFinalizar.btn.btn-contained
    {
        width: 450px;
        margin-left: -36px;
        margin-right: -43px;
      }     
    }


@media only screen and (max-width: 479px) and (min-width: 340px){
    .BotonFinalizar.btn.btn-contained{
        width: 100%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }  
}

@media only screen and (max-width: 339px){
    .BotonFinalizar.btn.btn-contained{
        width: 80%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }  
}
