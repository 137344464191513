@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.testClass123{
    font-family: Poppins !important;    
    margin-bottom: 15px;
    color: #2577DF !important;
    border: 1px solid #2577DF !important;
}

.MuiSvgIcon-root.MuiChip-deleteIcon{
    color: #2577DF !important;
}

.MuiTypography-h6{
    font-family: Poppins !important;
}