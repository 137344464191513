html{
    background-color: white;
}

.Nombre, .Plaza, .Numero, .Porcentaje{
    font-family: Poppins;
}

.Nombre{
    color: #363770;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; 
}


.Plaza {   
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    color: #363770;
    line-height: 100%; 
}

.Numero{  
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    color: #898989;
    line-height: 100%; 
}

.Porcentaje{
    height: 11px;
    left: 262px;
    top: 164px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;  
    text-align: center;
    letter-spacing: 1px;
    color: #757A86;
}
.Total {     
      
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;   
    letter-spacing: 0.3px;
    color: #1C2439;     
}

.column:last-child{
    float:none;
    width:auto;
    overflow:hidden;
    float:right;    
    background: #F1F4FA;
    border-radius: 8px;
    height: 70px;
}

.column{ 
padding: 10px 0;
}


/* PARA TAMAÑO DE CONTENEDORES*/


/*Pantalla pequeña*/
@media only screen and (max-width: 300px) {

    .column{
        width : 50.33%;
        float:left;
        margin-left: 20px !important; 
        margin: 0 1%;
        text-align: left;
    }
    .column:last-child{
        width : 60px !important;
        height: 50px;
        text-align: center;    
        margin-left: 16px;
        margin-right: 16px; 
    }
}

/*Pantalla pequeña intermedia*/
@media only screen and (min-width: 340px) and (max-width: 400px) {
    .column{
        width : 60.33%;
        float:left;        
        margin: 0 1%;
        text-align: left;
        margin-left: -10px;
        margin-right: -10px; 
    }
    .column:last-child{
        width : 70px !important;
        height: 60px;
        text-align: left;    
        margin-left: -16px;
        margin-right: -16px; 
    }
}



/*Pantalla pequeña intermedia*/
@media only screen and (min-width: 300px) and (max-width: 339px) {
    .column:last-child{
        width : 50px !important;
        height: 60px;
        text-align: center;    
        margin-left: 5px;
        margin-right: 5px; 
    }
    .column{
        width : 50.33%;
        float:left;                
        text-align: left;
        margin-left: 20px;
        margin-right: 20px; 
    }
    }


/*Pantalla pequeñas*/
@media only screen and (min-width: 401px) and (max-width: 479px){
    .column{
        width : 50.33%;
        float:left;
        margin: 0 1%;
        text-align: left;
        margin-left: -16px;
        margin-right: -16px; 
    }
    .column:last-child{
        height: 60px;
        width: 90px;
        margin-left: -16px;
        margin-right: -16px; 
    }
}



/*Pantalla intermedias */
@media only screen and (max-width: 599px)  and (min-width: 480px){
    .column{
        width : 63%;
        float:left;
        margin: 0 6%;                     
        margin-left: -32px;
        text-align: left;
    }
    .column:last-child{
        width : 90px !important;        
        margin-left: -40px;
        margin-right: -40px; 
        text-align: center;       
    }
}

/*Pantalla más grandes */
@media only screen and (max-width: 800px) and (min-width: 600px){
    .column{
        width : 80%;
        float:left;
        margin: 0 6%;                     
        margin-left: -32px;
        text-align: left;
    }
    .column:last-child{
        width : 101px !important;        
        margin-left: -50px;
        margin-right: -50px; 
        text-align: center;       
    }
}


/*Pantalla más grandes */
@media only screen and (min-width: 800px) {
    .column{
        width : 100%;
        float:left;
        margin: 0 6%;                     
        margin-left: -90px;
        text-align: left;
    }
    .column:last-child{
        width : 120px !important;        
        height: 75px;
        margin-left: -90px;
        margin-right: -90px; 
        text-align: center;       
    }
}



/* PARA TAMAÑO DE LETRA */

/*Pantalla grande  pequeña*/
@media only screen and (max-width: 359px){

    .Nombre {
        font-size: 8.7px;     
    }
    
    .Plaza {        
        font-size: 7.7px;        
    }
    
    .Numero {
        font-size: 7px;     
    }
    
    .Porcentaje{
        font-size: 9px;
    }
    .Total{
        font-size: 11px;
    }
    
    .MuiSvgIcon-root.PhoneIcon{    
        width: 15%;
    }
}

/*Pantalla grande  mediana*/
@media only screen and (max-width: 499px) and (min-width: 360px){

    .Nombre {
        font-size: 12px;     
    }
    
    .Plaza {        
        font-size: 10px;        
    }
    
    .Numero {
        font-size: 9px;     
    }
    
    .Porcentaje{
        font-size: 12px;
    }
    .Total{
        font-size: 13px;
    }
    .MuiSvgIcon-root.PhoneIcon{    
        width: 13%;
    }
}

/*Pantalla grande */
@media only screen and (max-width: 599px) and (min-width: 500px){
    .Nombre {
        font-size: 14px;    
    }

    .Plaza {        
        font-size: 11px;       
    }

    .Numero {
        font-size: 10px;       
    }

    .Porcentaje{
        font-size: 13px;
    }
    .Total{
        font-size: 15px;
    }
}

/*Pantalla grande */
@media only screen and (min-width: 600px){
    .Nombre {
        font-size: 16px;  
    }

    .Plaza {        
        font-size: 12px;      
    }

    .Numero {
        font-size: 11px;      
    }

    .Porcentaje{
        font-size: 15px;
    }
    .Total{
        font-size: 17px;
    }
}


.MuiSvgIcon-root.PhoneIcon{    
    color: #989898;
}

