.loading-backdrop
{
top: 0;
left: 0;
position: fixed;
 background:  rgba(255,255,255,0.85);
 width: 100%;
 height: 100%;
 z-index: 10000;
 display: flex;
 align-items: center;
 justify-content: center;
}