.Icono {
  width: 100px;
  color: black;
  margin-bottom: -30px;
}
.Titulo {
  color: black;
  font-weight: 500;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.Botones.btn.btn-contained,
.BotonEnviar.btn.btn-contained {
  background-color: #2577df;
  color: #ffffff;

  margin: 3px 0px 2px;
  height: 45px;
}

.Botones.btn.btn-contained {
  width: 100%;
}

.Botones.btn.btn-contained {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.BotonEnviar.btn.btn-contained {
  left: 150px;
  top: 558px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.MuiTypography-root.Footer.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-alignCenter {
  font-family: "Roboto", sans-serif;
  color: #989898;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-required.Mui-required,
.MuiInputBase-input.MuiOutlinedInput-input {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
}

.MuiInputBase-input.MuiOutlinedInput-input {
  color: black;
}

@media only screen and (min-width: 800px) {
  .nav li {
    display: inline-block;
    margin: 1.6em;
  }
}

@media only screen and (min-width: 480px) and (max-width: 799px) {
  .nav li {
    display: inline-block;
    margin: 1em;
  }
}

@media only screen and (max-width: 479px) {
  .nav li {
    display: inline-block;
  }
}

.Botones.btn.btn-contained {
  font-weight: 800;
}
