.LogoTipo{
    width: 350px;
    display:block;
margin:auto;
}

@media only screen and (max-width: 359px)  {
    .LogoTipo
    {
        width: 270px;
        height: 100%;           
    } 
}

@media only screen and (max-width: 410px) and (min-width: 360px) {
    .LogoTipo
    {
        width: 306px;
        height: 100%;           
    } 
}


@media only screen and (min-width: 411px) and (max-width: 600px) {
    .LogoTipo
    {
        width: 370px;                       
    }    

}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
    .LogoTipo
    {
        width: 450px;                       
    } 

}

@media only screen and (min-width: 1201px){
    .LogoTipo
    {
        width: 500px;                            
    }   
}
