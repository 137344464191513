@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
/* @media (max-width: 800 px){
    .paper{
        width: 500px;
        height: 100vh;
    }
}

@media only screen and (max-width: 800px) and (min-width: 0px) {
    .paper2{
        width: 515px;
        height: 100%;
    }
}

@media only screen and (max-width:  900px) and (min-width: 0px) {
    .imageMrbPequeno{
        width: 200px;
        height: auto;
        padding-top: 40px;
        padding-bottom: 20px;

    }
}

@media only screen and (max-width: 900px) and (min-width: 0px) {
    .imageFondoPequeno{
        width: 515px;
        height: auto;
    }
}



@media only screen and (max-width:  999px) and (min-width: 901px) {
    .imageMrbPequenoPequeno2{
        width: 200px;
        height: auto;
        padding-top: 40px;
        padding-bottom: 20px;

    }
}

@media only screen and (max-width: 999px) and (min-width: 901px) {
    .imageFondoPequenoPequeno2{
        width: 925px;
        height: auto;
    }
}


@media (min-width: 1000px){
    .imageMrbGrande{
        width: 200px;
        height: auto;
        padding-top: 40px;
        padding-bottom: 20px;
    }
}

 @media (min-width:  1100px){
    .imageFondoGrande{
        width: 1335px;
        height: 440px;
    }
} 



@media only screen and (max-width: 3000px) and (min-width: 900px) {
    .paper3{
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 999px) and (min-width: 0px){
    .textField{
        width: 60ch;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
@media only screen and (max-width: 3000px) and (min-width: 1000px) {
    .textFieldGrande{
        width: 70ch;
        padding-top: 20px;
        padding-bottom:2ex;
    }
} */

/* 
@media only screen and (max-width: 3000px) and (min-width: 900px) {
    .paper3{
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 800px) and (min-width: 0px) {
    .paper2{
        width: 515px;
        height: 100%;
    }
} */

.LogoTipo{
    width: 350px;
    display:block;
margin:auto;
}

@media only screen and (max-width: 359px)  {
    .LogoTipo
    {
        width: 270px;
        height: 100%;           
    } 
}

@media only screen and (max-width: 410px) and (min-width: 360px) {
    .LogoTipo
    {
        width: 306px;
        height: 100%;           
    } 
}


@media only screen and (min-width: 411px) and (max-width: 600px) {
    .LogoTipo
    {
        width: 370px;                       
    }    

}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
    .LogoTipo
    {
        width: 450px;                       
    } 

}

@media only screen and (min-width: 1201px){
    .LogoTipo
    {
        width: 500px;                            
    }   
}

.MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  font-family: Poppins;
  font-weight: 800;
}

.MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-family: Poppins;
  font-weight: 800;
}

.MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd {
  font-family: Poppins;
  font-weight: 800;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.BotonBuscar {
  background-color: #2577df;
  color: white;
  margin-top: 1.5%;
  height: 30px;
}

.loading-backdrop
{
top: 0;
left: 0;
position: fixed;
 background:  rgba(255,255,255,0.85);
 width: 100%;
 height: 100%;
 z-index: 10000;
 display: flex;
 align-items: center;
 justify-content: center;
}
.BotonFinalizar.btn.btn-contained
{
    background-color:#2577DF; 
    color: #FFFFFF;
    margin: 3px 0px 2px;
    height: 45px;
    font-family: 'Roboto sans-serif';
}


@media only screen and (min-width: 800px) {
    .BotonFinalizar.btn.btn-contained{
        width: 550px;
        height: 50px;
        margin-left: -94px;
        margin-right: -100px;
      }      
    }
    
@media only screen and (min-width: 600px) and (max-width: 799px) {
    .BotonFinalizar.btn.btn-contained 
    {
    width: 450px;
    margin-left: -15px;
    margin-right: -41px;
  }
}


@media only screen and (max-width: 599px) and (min-width: 480px) {
    .BotonFinalizar.btn.btn-contained
    {
        width: 450px;
        margin-left: -36px;
        margin-right: -43px;
      }     
    }


@media only screen and (max-width: 479px) and (min-width: 340px){
    .BotonFinalizar.btn.btn-contained{
        width: 100%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }  
}

@media only screen and (max-width: 339px){
    .BotonFinalizar.btn.btn-contained{
        width: 80%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }  
}

*/ .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  color: #2577df;
  font-family: "Roboto", sans-serif;
  font-weight: 800 !important;
}



/*Pantalla telefono */
@media only screen and (max-width: 349px){    
    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 10px;
    }
}


/*Pantalla telefono */
@media only screen and (max-width: 499px) and (min-width: 350px){
  
    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 14px;
    }
}

/*Pantalla telefono */
@media only screen and (max-width: 799px)  and (min-width: 500px){

    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 16px;
    }
}


/*Pantalla escritorio */
@media only screen and (min-width: 800px){
    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 20px;
    }
}


@media only screen and (min-width: 800px) {
    .BotonEnviar.btn.btn-contained {
        width: 550px;
        height: 50px;
        margin-left: -94px;
        margin-right: -100px;
      }
      .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
          width: 550px;
      }
    }
    
@media only screen and (min-width: 600px) and (max-width: 799px) {
.BotonEnviar.btn.btn-contained{
    width: 450px;
    margin-left: -15px;
    margin-right: -41px;
  }
  .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
    width: 450px;
}
}


@media only screen and (max-width: 599px) and (min-width: 480px) {
    .BotonEnviar.btn.btn-contained{
        width: 450px;
        margin-left: -36px;
        margin-right: -43px;
      }
      .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
        width: 450px;
    }
    }


@media only screen and (max-width: 479px) and (min-width: 340px){
    .Root, .BotonEnviar.btn.btn-contained{
        width: 100%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }
    .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
        width: 80%;
    }
}

@media only screen and (max-width: 339px){
    .Root, .BotonEnviar.btn.btn-contained{
        width: 80%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }
    .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
        width: 80%;
    }
}

    
@media only screen and (min-width: 480px) and (max-width: 799px) {
    .Root{
        width: 450px;
        height: 200%;
        margin-left: -15px !important;
        }
    }

@media only screen and (min-width: 800px) {
.Root{
    width: 550px;
    height: 300%;
    margin-left: -75px !important;
    }
}

.contenedor {
    position: relative;
  }
  
  .hijo {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
.BotonEnviar.btn.btn-contained
{
    background-color:#2577DF; 
    color: #FFFFFF;
    margin: 3px 0px 2px;
    height: 45px;
}

.testClass123{
    font-family: Poppins !important;    
    margin-bottom: 15px;
    color: #2577DF !important;
    border: 1px solid #2577DF !important;
}

.MuiSvgIcon-root.MuiChip-deleteIcon{
    color: #2577DF !important;
}

.MuiTypography-h6{
    font-family: Poppins !important;
}
html{
    background-color: white;
}

.Nombre, .Plaza, .Numero, .Porcentaje{
    font-family: Poppins;
}

.Nombre{
    color: #363770;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; 
}


.Plaza {   
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    color: #363770;
    line-height: 100%; 
}

.Numero{  
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    color: #898989;
    line-height: 100%; 
}

.Porcentaje{
    height: 11px;
    left: 262px;
    top: 164px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;  
    text-align: center;
    letter-spacing: 1px;
    color: #757A86;
}
.Total {     
      
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;   
    letter-spacing: 0.3px;
    color: #1C2439;     
}

.column:last-child{
    float:none;
    width:auto;
    overflow:hidden;
    float:right;    
    background: #F1F4FA;
    border-radius: 8px;
    height: 70px;
}

.column{ 
padding: 10px 0;
}


/* PARA TAMAÑO DE CONTENEDORES*/


/*Pantalla pequeña*/
@media only screen and (max-width: 300px) {

    .column{
        width : 50.33%;
        float:left;
        margin-left: 20px !important; 
        margin: 0 1%;
        text-align: left;
    }
    .column:last-child{
        width : 60px !important;
        height: 50px;
        text-align: center;    
        margin-left: 16px;
        margin-right: 16px; 
    }
}

/*Pantalla pequeña intermedia*/
@media only screen and (min-width: 340px) and (max-width: 400px) {
    .column{
        width : 60.33%;
        float:left;        
        margin: 0 1%;
        text-align: left;
        margin-left: -10px;
        margin-right: -10px; 
    }
    .column:last-child{
        width : 70px !important;
        height: 60px;
        text-align: left;    
        margin-left: -16px;
        margin-right: -16px; 
    }
}



/*Pantalla pequeña intermedia*/
@media only screen and (min-width: 300px) and (max-width: 339px) {
    .column:last-child{
        width : 50px !important;
        height: 60px;
        text-align: center;    
        margin-left: 5px;
        margin-right: 5px; 
    }
    .column{
        width : 50.33%;
        float:left;                
        text-align: left;
        margin-left: 20px;
        margin-right: 20px; 
    }
    }


/*Pantalla pequeñas*/
@media only screen and (min-width: 401px) and (max-width: 479px){
    .column{
        width : 50.33%;
        float:left;
        margin: 0 1%;
        text-align: left;
        margin-left: -16px;
        margin-right: -16px; 
    }
    .column:last-child{
        height: 60px;
        width: 90px;
        margin-left: -16px;
        margin-right: -16px; 
    }
}



/*Pantalla intermedias */
@media only screen and (max-width: 599px)  and (min-width: 480px){
    .column{
        width : 63%;
        float:left;
        margin: 0 6%;                     
        margin-left: -32px;
        text-align: left;
    }
    .column:last-child{
        width : 90px !important;        
        margin-left: -40px;
        margin-right: -40px; 
        text-align: center;       
    }
}

/*Pantalla más grandes */
@media only screen and (max-width: 800px) and (min-width: 600px){
    .column{
        width : 80%;
        float:left;
        margin: 0 6%;                     
        margin-left: -32px;
        text-align: left;
    }
    .column:last-child{
        width : 101px !important;        
        margin-left: -50px;
        margin-right: -50px; 
        text-align: center;       
    }
}


/*Pantalla más grandes */
@media only screen and (min-width: 800px) {
    .column{
        width : 100%;
        float:left;
        margin: 0 6%;                     
        margin-left: -90px;
        text-align: left;
    }
    .column:last-child{
        width : 120px !important;        
        height: 75px;
        margin-left: -90px;
        margin-right: -90px; 
        text-align: center;       
    }
}



/* PARA TAMAÑO DE LETRA */

/*Pantalla grande  pequeña*/
@media only screen and (max-width: 359px){

    .Nombre {
        font-size: 8.7px;     
    }
    
    .Plaza {        
        font-size: 7.7px;        
    }
    
    .Numero {
        font-size: 7px;     
    }
    
    .Porcentaje{
        font-size: 9px;
    }
    .Total{
        font-size: 11px;
    }
    
    .MuiSvgIcon-root.PhoneIcon{    
        width: 15%;
    }
}

/*Pantalla grande  mediana*/
@media only screen and (max-width: 499px) and (min-width: 360px){

    .Nombre {
        font-size: 12px;     
    }
    
    .Plaza {        
        font-size: 10px;        
    }
    
    .Numero {
        font-size: 9px;     
    }
    
    .Porcentaje{
        font-size: 12px;
    }
    .Total{
        font-size: 13px;
    }
    .MuiSvgIcon-root.PhoneIcon{    
        width: 13%;
    }
}

/*Pantalla grande */
@media only screen and (max-width: 599px) and (min-width: 500px){
    .Nombre {
        font-size: 14px;    
    }

    .Plaza {        
        font-size: 11px;       
    }

    .Numero {
        font-size: 10px;       
    }

    .Porcentaje{
        font-size: 13px;
    }
    .Total{
        font-size: 15px;
    }
}

/*Pantalla grande */
@media only screen and (min-width: 600px){
    .Nombre {
        font-size: 16px;  
    }

    .Plaza {        
        font-size: 12px;      
    }

    .Numero {
        font-size: 11px;      
    }

    .Porcentaje{
        font-size: 15px;
    }
    .Total{
        font-size: 17px;
    }
}


.MuiSvgIcon-root.PhoneIcon{    
    color: #989898;
}


html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto sans-serif", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetical Neue", sans-serif;
  color: rgba(0, 0, 0, 0.48) !important;
  font-weight: 500 !important;
  background-color: white !important;
}

.helper {
  --reactour-accent: black;
  line-height: 1.3;
  color: black;
}
.Titulo,
.Titulo_Alerta {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #8b9095 !important;
  margin-left: -5px !important;
}

.svg-inline--fa.fa-upload.fa-w-16.IconoSubir,
.svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje,
.svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
  color: black;
}

@media only screen and (min-width: 800px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 15px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 799px) {
  .Titulo {
    font-size: 12px;
  }
  .Titulo_Alerta {
    font-size: 11px;
  }
}

@media only screen and (max-width: 339px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 8px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 10px;
    height: 10px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 12px;
    height: 12px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 11px;
    height: 11px;
  }
  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -3px !important;
  }
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 6px;
    height: 50%;
    margin-right: -3px !important;
  }
}

@media only screen and (max-width: 359px) and (min-width: 340px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 9px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 15px;
    height: 15px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 15px;
    height: 15px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 16px;
    height: 16px;
  }
  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -0.5px !important;
  }
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 7px;
    height: 60%;
  }
}

@media only screen and (max-width: 373px) and (min-width: 360px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 9px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 15px;
    height: 15px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 15px;
    height: 15px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 16px;
    height: 16px;
  }
  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -0.5px !important;
  }
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 7px;
    height: 60%;
  }
}

@media only screen and (max-width: 479px) and (min-width: 374px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 9px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 15px;
    height: 15px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 15px;
    height: 15px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 16px;
    height: 16px;
  }

  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 7px;
    height: 70%;
  }

  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -1px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 699px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 10px;
  }
}

@media only screen and (min-width: 700px) {
  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    margin-left: -15px;
  }
}

@media only screen and (min-width: 480px) {
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 22px;
    height: 22px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 23px;
    height: 23px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 21px;
    height: 21px;
  }

  .Alinear {
    margin-top: -1px !important;
  }
}

@media only screen and (min-width: 800px) {
  .AlinearMensaje {
    margin-top: -8px !important;
  }
  .Titulo_Alerta {
    margin-left: 10px !important;
    margin-top: -3px;
  }
}

@media only screen and (max-width: 799px) and (min-width: 360px) {
  .AlinearMensaje {
    margin-top: -9px !important;
  }
  .Titulo_Alerta {
    margin-left: 4px !important;
    margin-top: -1px;
  }
}

@media only screen and (max-width: 359px) and (min-width: 340px) {
  .AlinearMensaje {
    margin-top: -11px !important;
  }
  .Titulo_Alerta {
    margin-left: 6px !important;
    margin-top: -1px;
  }
}

@media only screen and (max-width: 339px) {
  .AlinearMensaje {
    margin-top: -13px !important;
  }

  .MuiButtonBase-root.MuiIconButton-root.BotonMensaje {
    margin-top: -9px !important;
  }
  .Titulo_Alerta {
    margin-left: 5px !important;
    margin-top: 3px;
  }
}

.MuiTypography-root.heading.MuiTypography-body1 {
  text-align: left;
  left: 17px;
  right: 233px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: #090a0a;
}

.Mensaje_error {
  color: #090a0a;
  top: 115px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}

.MuiTypography-colorTextSecondary {
  color: #000 !important;
  font-weight: 800 !important;
}

.MuiButton-textPrimary {
  color:blue !important;
  font-weight: 800 !important;
}
.Icono {
  width: 100px;
  color: black;
  margin-bottom: -30px;
}
.Titulo {
  color: black;
  font-weight: 500;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.Botones.btn.btn-contained,
.BotonEnviar.btn.btn-contained {
  background-color: #2577df;
  color: #ffffff;

  margin: 3px 0px 2px;
  height: 45px;
}

.Botones.btn.btn-contained {
  width: 100%;
}

.Botones.btn.btn-contained {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.BotonEnviar.btn.btn-contained {
  left: 150px;
  top: 558px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.MuiTypography-root.Footer.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-alignCenter {
  font-family: "Roboto", sans-serif;
  color: #989898;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-required.Mui-required,
.MuiInputBase-input.MuiOutlinedInput-input {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
}

.MuiInputBase-input.MuiOutlinedInput-input {
  color: black;
}

@media only screen and (min-width: 800px) {
  .nav li {
    display: inline-block;
    margin: 1.6em;
  }
}

@media only screen and (min-width: 480px) and (max-width: 799px) {
  .nav li {
    display: inline-block;
    margin: 1em;
  }
}

@media only screen and (max-width: 479px) {
  .nav li {
    display: inline-block;
  }
}

.Botones.btn.btn-contained {
  font-weight: 800;
}

@media only screen and (min-width: 800px) {
  .Root {
    width: 550px;
    height: 300%;
    margin-left: -75px !important;
  }
}

@media only screen and (min-width: 800px) {
  .BotonEnviar.btn.btn-contained {
    width: 550px;
    height: 50px;
    margin-left: -94px;
    margin-right: -100px;
    font-weight: 800;
  }
}

@media only screen and (min-width: 480px) and (max-width: 799px) {
  .Root {
    width: 450px;
    height: 200%;
    margin-left: -15px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
  .BotonEnviar.btn.btn-contained {
    width: 450px;
    margin-left: -15px;
    font-weight: 800;
    margin-right: -41px;
  }
}

@media only screen and (max-width: 599px) and (min-width: 480px) {
  .BotonEnviar.btn.btn-contained {
    width: 450px;
    margin-left: -36px;
    margin-right: -43px;
    font-weight: 800;
  }
}

@media only screen and (max-width: 479px) and (min-width: 340px) {
  .Root,
  .BotonEnviar.btn.btn-contained {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
  }
}

@media only screen and (max-width: 339px) {
  .Root,
  .BotonEnviar.btn.btn-contained {
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
  }
}

.Texto {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff;
}

.Mensajes {
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  height: 18px;
  left: 17px;
  right: 317px;
  top: 241px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;

  line-height: 18px;
  /* identical to box height, or 112% */

  letter-spacing: 0.3px;
  color: #090a0a;
  flex-basis: 33.33%;
  flex-basis: 0;
  font-weight: 700;
}

.absolute {
  text-align: center;
  color: white;
  margin-left: auto;
  border-radius: 32px;
}

/*Pantalla telefono */
@media only screen and (max-width: 799px) and (min-width: 426px) {
  .absolute {
    width: 75px;
    height: 28px;
    padding: 6px;
  }
  .Texto {
    font-size: 11px;
  }
  .Mensajes {
    font-size: 11px;
  }
}

@media only screen and (max-width: 425px) {
  .absolute {
    width: 63px;
    height: 24px;
    padding: 5px;
  }
  .Texto {
    font-size: 9px;
  }
  .Mensajes {
    font-size: 11px;
  }
}

/*Pantalla escritorio */
@media only screen and (min-width: 800px) {
  .absolute {
    width: 100px;
    height: 36px;
    padding: 10px;
  }
  .Texto {
    font-size: 13px;
  }
  .Mensajes {
    font-size: 15px;
  }
}

/*Pantalla telefono */
@media only screen and (max-width: 349px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 9px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 10px;
  }
}

/*Pantalla telefono */
@media only screen and (max-width: 499px) and (min-width: 350px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 11px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 14px;
  }
}

/*Pantalla telefono */
@media only screen and (max-width: 799px) and (min-width: 500px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 12px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 16px;
  }
}

/*Pantalla escritorio */
@media only screen and (min-width: 800px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 14px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 20px;
  }
}

.MuiTypography-root.secondaryHeading.MuiTypography-body1 {
  text-align: left;
  left: 17px;
  right: 200px;
  top: 440.35px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.3px;
  color: #8b9095;
}

.MuiTypography-root.Heading.MuiTypography-body1 {
  text-align: left;
  left: 17px;
  right: 233px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: #090a0a;
}



/*--------------------------------pantallas pequeñas */ 

@media only screen and (max-width: 359px)  {
    .Logo
    {
        width: 270px;
        height: 100%;           
    } 
}

@media only screen and (max-width: 410px) and (min-width: 360px) {
    .Logo
    {
        width: 306px;
        height: 100%;           
    } 
}

/*--------------------------------pantallas pequeñas */ 
@media only screen and (max-width: 410px) {
    .Titulo{
        width: 150px;
    }
    .instrucciones{
        font-size: 10px;
    }
    .Mensaje{
        font-size: 11px;
        margin-left: 20px !important;
    }

}

@media only screen and (min-width: 411px) and (max-width: 600px) {
    .Logo
    {
        width: 370px;    
        margin-left: -25px !important;                    
    } 
    .Titulo{
        width: 200px;
    }
    .instrucciones{
     
        font-size: 10px;
    }
    .Mensaje{
         font-size: 10px;
    }

}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
    .Logo
    {
        width: 450px;
        margin-left: -55px !important;                        
    } 
    .Titulo{
        width: 275px;
    }
    .instrucciones{     
        font-size: 14px;
    }
    .Mensaje{     
        font-size: 14px;
    }

}

@media only screen and (min-width: 1201px){
    .Logo
    {
        width: 500px;   
        margin-left: -75px !important;                  
    } 
    .Titulo{
        width: 280px;
    }
    .instrucciones{
        font-size: 14px;
    }
    .Mensaje{
        font-size: 14px;       
    }
}

.instrucciones{
   
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: #898989;
}
.Mensaje{
    line-height: 18px;
    color: #C4C4C4;
    width: 322px;
    height: 18px;
    left: 16px;
    top: 115px;    
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
}

hr {
    border:none;
    border-top: 2px solid white;
    width: 20%;
  }




