@media only screen and (min-width: 800px) {
  .Root {
    width: 550px;
    height: 300%;
    margin-left: -75px !important;
  }
}

@media only screen and (min-width: 800px) {
  .BotonEnviar.btn.btn-contained {
    width: 550px;
    height: 50px;
    margin-left: -94px;
    margin-right: -100px;
    font-weight: 800;
  }
}

@media only screen and (min-width: 480px) and (max-width: 799px) {
  .Root {
    width: 450px;
    height: 200%;
    margin-left: -15px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
  .BotonEnviar.btn.btn-contained {
    width: 450px;
    margin-left: -15px;
    font-weight: 800;
    margin-right: -41px;
  }
}

@media only screen and (max-width: 599px) and (min-width: 480px) {
  .BotonEnviar.btn.btn-contained {
    width: 450px;
    margin-left: -36px;
    margin-right: -43px;
    font-weight: 800;
  }
}

@media only screen and (max-width: 479px) and (min-width: 340px) {
  .Root,
  .BotonEnviar.btn.btn-contained {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
  }
}

@media only screen and (max-width: 339px) {
  .Root,
  .BotonEnviar.btn.btn-contained {
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
  }
}

.Texto {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff;
}

.Mensajes {
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  height: 18px;
  left: 17px;
  right: 317px;
  top: 241px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;

  line-height: 18px;
  /* identical to box height, or 112% */

  letter-spacing: 0.3px;
  color: #090a0a;
  flex-basis: 33.33%;
  flex-basis: 0;
  font-weight: 700;
}

.absolute {
  text-align: center;
  color: white;
  margin-left: auto;
  border-radius: 32px;
}

/*Pantalla telefono */
@media only screen and (max-width: 799px) and (min-width: 426px) {
  .absolute {
    width: 75px;
    height: 28px;
    padding: 6px;
  }
  .Texto {
    font-size: 11px;
  }
  .Mensajes {
    font-size: 11px;
  }
}

@media only screen and (max-width: 425px) {
  .absolute {
    width: 63px;
    height: 24px;
    padding: 5px;
  }
  .Texto {
    font-size: 9px;
  }
  .Mensajes {
    font-size: 11px;
  }
}

/*Pantalla escritorio */
@media only screen and (min-width: 800px) {
  .absolute {
    width: 100px;
    height: 36px;
    padding: 10px;
  }
  .Texto {
    font-size: 13px;
  }
  .Mensajes {
    font-size: 15px;
  }
}

/*Pantalla telefono */
@media only screen and (max-width: 349px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 9px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 10px;
  }
}

/*Pantalla telefono */
@media only screen and (max-width: 499px) and (min-width: 350px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 11px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 14px;
  }
}

/*Pantalla telefono */
@media only screen and (max-width: 799px) and (min-width: 500px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 12px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 16px;
  }
}

/*Pantalla escritorio */
@media only screen and (min-width: 800px) {
  .MuiTypography-root.secondaryHeading.MuiTypography-body1 span {
    font-size: 14px;
  }
  .MuiTypography-root.Heading.MuiTypography-body1 {
    font-size: 20px;
  }
}

.MuiTypography-root.secondaryHeading.MuiTypography-body1 {
  text-align: left;
  left: 17px;
  right: 200px;
  top: 440.35px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.3px;
  color: #8b9095;
}

.MuiTypography-root.Heading.MuiTypography-body1 {
  text-align: left;
  left: 17px;
  right: 233px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: #090a0a;
}
