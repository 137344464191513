html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto sans-serif", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetical Neue", sans-serif;
  color: rgba(0, 0, 0, 0.48) !important;
  font-weight: 500 !important;
  background-color: white !important;
}

.helper {
  --reactour-accent: black;
  line-height: 1.3;
  color: black;
}