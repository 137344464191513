

/*Pantalla telefono */
@media only screen and (max-width: 349px){    
    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 10px;
    }
}


/*Pantalla telefono */
@media only screen and (max-width: 499px) and (min-width: 350px){
  
    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 14px;
    }
}

/*Pantalla telefono */
@media only screen and (max-width: 799px)  and (min-width: 500px){

    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 16px;
    }
}


/*Pantalla escritorio */
@media only screen and (min-width: 800px){
    .MuiTypography-root.Heading.MuiTypography-body1{
        font-size: 20px;
    }
}


@media only screen and (min-width: 800px) {
    .BotonEnviar.btn.btn-contained {
        width: 550px;
        height: 50px;
        margin-left: -94px;
        margin-right: -100px;
      }
      .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
          width: 550px;
      }
    }
    
@media only screen and (min-width: 600px) and (max-width: 799px) {
.BotonEnviar.btn.btn-contained{
    width: 450px;
    margin-left: -15px;
    margin-right: -41px;
  }
  .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
    width: 450px;
}
}


@media only screen and (max-width: 599px) and (min-width: 480px) {
    .BotonEnviar.btn.btn-contained{
        width: 450px;
        margin-left: -36px;
        margin-right: -43px;
      }
      .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
        width: 450px;
    }
    }


@media only screen and (max-width: 479px) and (min-width: 340px){
    .Root, .BotonEnviar.btn.btn-contained{
        width: 100%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }
    .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
        width: 80%;
    }
}

@media only screen and (max-width: 339px){
    .Root, .BotonEnviar.btn.btn-contained{
        width: 80%;
        height: 100%; 
        margin-left: auto;        
        margin-right: auto;
    }
    .MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion{
        width: 80%;
    }
}

    
@media only screen and (min-width: 480px) and (max-width: 799px) {
    .Root{
        width: 450px;
        height: 200%;
        margin-left: -15px !important;
        }
    }

@media only screen and (min-width: 800px) {
.Root{
    width: 550px;
    height: 300%;
    margin-left: -75px !important;
    }
}

.contenedor {
    position: relative;
  }
  
  .hijo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
.BotonEnviar.btn.btn-contained
{
    background-color:#2577DF; 
    color: #FFFFFF;
    margin: 3px 0px 2px;
    height: 45px;
}
