.Titulo,
.Titulo_Alerta {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #8b9095 !important;
  margin-left: -5px !important;
}

.svg-inline--fa.fa-upload.fa-w-16.IconoSubir,
.svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje,
.svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
  color: black;
}

@media only screen and (min-width: 800px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 15px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 799px) {
  .Titulo {
    font-size: 12px;
  }
  .Titulo_Alerta {
    font-size: 11px;
  }
}

@media only screen and (max-width: 339px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 8px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 10px;
    height: 10px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 12px;
    height: 12px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 11px;
    height: 11px;
  }
  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -3px !important;
  }
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 6px;
    height: 50%;
    margin-right: -3px !important;
  }
}

@media only screen and (max-width: 359px) and (min-width: 340px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 9px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 15px;
    height: 15px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 15px;
    height: 15px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 16px;
    height: 16px;
  }
  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -0.5px !important;
  }
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 7px;
    height: 60%;
  }
}

@media only screen and (max-width: 373px) and (min-width: 360px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 9px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 15px;
    height: 15px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 15px;
    height: 15px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 16px;
    height: 16px;
  }
  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -0.5px !important;
  }
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 7px;
    height: 60%;
  }
}

@media only screen and (max-width: 479px) and (min-width: 374px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 9px;
  }
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 15px;
    height: 15px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 15px;
    height: 15px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 16px;
    height: 16px;
  }

  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
    font-size: 7px;
    height: 70%;
  }

  .AlinearFile {
    margin-top: -2px !important;
  }
  .Alinear {
    margin-top: -1px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 699px) {
  .Titulo,
  .Titulo_Alerta {
    font-size: 10px;
  }
}

@media only screen and (min-width: 700px) {
  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    margin-left: -15px;
  }
}

@media only screen and (min-width: 480px) {
  .svg-inline--fa.fa-search.fa-w-16.IconoBuscar {
    width: 22px;
    height: 22px;
  }

  .svg-inline--fa.fa-comment-dots.fa-w-16.IconoMensaje {
    width: 23px;
    height: 23px;
  }
  .svg-inline--fa.fa-upload.fa-w-16.IconoSubir {
    width: 21px;
    height: 21px;
  }

  .Alinear {
    margin-top: -1px !important;
  }
}

@media only screen and (min-width: 800px) {
  .AlinearMensaje {
    margin-top: -8px !important;
  }
  .Titulo_Alerta {
    margin-left: 10px !important;
    margin-top: -3px;
  }
}

@media only screen and (max-width: 799px) and (min-width: 360px) {
  .AlinearMensaje {
    margin-top: -9px !important;
  }
  .Titulo_Alerta {
    margin-left: 4px !important;
    margin-top: -1px;
  }
}

@media only screen and (max-width: 359px) and (min-width: 340px) {
  .AlinearMensaje {
    margin-top: -11px !important;
  }
  .Titulo_Alerta {
    margin-left: 6px !important;
    margin-top: -1px;
  }
}

@media only screen and (max-width: 339px) {
  .AlinearMensaje {
    margin-top: -13px !important;
  }

  .MuiButtonBase-root.MuiIconButton-root.BotonMensaje {
    margin-top: -9px !important;
  }
  .Titulo_Alerta {
    margin-left: 5px !important;
    margin-top: 3px;
  }
}

.MuiTypography-root.heading.MuiTypography-body1 {
  text-align: left;
  left: 17px;
  right: 233px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: #090a0a;
}

.Mensaje_error {
  color: #090a0a;
  top: 115px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}

.MuiTypography-colorTextSecondary {
  color: #000 !important;
  font-weight: 800 !important;
}

.MuiButton-textPrimary {
  color:blue !important;
  font-weight: 800 !important;
}