

/*--------------------------------pantallas pequeñas */ 

@media only screen and (max-width: 359px)  {
    .Logo
    {
        width: 270px;
        height: 100%;           
    } 
}

@media only screen and (max-width: 410px) and (min-width: 360px) {
    .Logo
    {
        width: 306px;
        height: 100%;           
    } 
}

/*--------------------------------pantallas pequeñas */ 
@media only screen and (max-width: 410px) {
    .Titulo{
        width: 150px;
    }
    .instrucciones{
        font-size: 10px;
    }
    .Mensaje{
        font-size: 11px;
        margin-left: 20px !important;
    }

}

@media only screen and (min-width: 411px) and (max-width: 600px) {
    .Logo
    {
        width: 370px;    
        margin-left: -25px !important;                    
    } 
    .Titulo{
        width: 200px;
    }
    .instrucciones{
     
        font-size: 10px;
    }
    .Mensaje{
         font-size: 10px;
    }

}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
    .Logo
    {
        width: 450px;
        margin-left: -55px !important;                        
    } 
    .Titulo{
        width: 275px;
    }
    .instrucciones{     
        font-size: 14px;
    }
    .Mensaje{     
        font-size: 14px;
    }

}

@media only screen and (min-width: 1201px){
    .Logo
    {
        width: 500px;   
        margin-left: -75px !important;                  
    } 
    .Titulo{
        width: 280px;
    }
    .instrucciones{
        font-size: 14px;
    }
    .Mensaje{
        font-size: 14px;       
    }
}

.instrucciones{
   
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: #898989;
}
.Mensaje{
    line-height: 18px;
    color: #C4C4C4;
    width: 322px;
    height: 18px;
    left: 16px;
    top: 115px;    
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
}

hr {
    border:none;
    border-top: 2px solid white;
    width: 20%;
  }


