.MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  font-family: Poppins;
  font-weight: 800;
}

.MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-family: Poppins;
  font-weight: 800;
}

.MuiFormControl-root.MuiTextField-root.DocumentoIdentificacion
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd {
  font-family: Poppins;
  font-weight: 800;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.BotonBuscar {
  background-color: #2577df;
  color: white;
  margin-top: 1.5%;
  height: 30px;
}
